import { YesNoTriggers } from 'components/Input'
import { KeyLocation } from 'components/Select/CarKeySelect'
import { VehicleConditions } from 'components/VehicleCondition'
import { TireCondition } from 'forms/CallForm/TireRepair/TireConditionRadio'
import { LocationState, LocationPayload } from './location'
import { DriverPhone, TransformedVehicle } from './ticket'
import { Branch } from './branch'
import { CarClassCategories } from './carClass'
import { Driver, DriverAddress } from './driver'
import {
  WorkflowActions,
  Caller,
  SelectOption,
  Maybe,
  CallerPhone,
  AssignmentNetworks,
  LegalId,
  YesNo,
  Vehicle,
  PartialDispatch,
  Participant,
  TypeCountryOptions,
} from './global'
import { PartialVehicle, PartialVehicleWithStringYear, PartialULYDispatch } from './urgently'

export type Accident = {
  vehicle: PartialVehicle
  serviceLocations: LocationState
  drivable: YesNoTriggers
  alreadyTowed?: YesNoTriggers
} & Pick<WorkflowActions, 'exchange' | 'tow' | 'recovery' | 'ldr'> &
  Breakdown

export type Breakdown = {
  unsafe: YesNoTriggers
  garageAccessible: YesNoTriggers
  accessible: YesNoTriggers
  breakdownOption: string
}

export type Callers = Caller & {
  callerRelationship: SelectOption<CallerRelationship>
  drivers: Maybe<Caller[]>
  selectedCaller: Maybe<string>
  number: Maybe<string>
  countryCode: SelectOption<any>
  /** only used for analytics */
  confirmedDOB: boolean
  companyConfirmation?: boolean
  optIn: boolean
}

export enum CallerRelationship {
  POLICE = 'police',
  PASSENGER = 'passenger',
  SPOUSE = 'spouse',
  COWORKER = 'coworker',
  TOWPROVIDER = 'towprovider',
}

export const phoneDefault: CallerPhone = {
  number: null,
  countryOption: null,
  countryCode: {
    label: 'United States +1',
    value: 'us',
  },
}

export const relationshipDefault: SelectOption = {
  label: '',
  value: '',
}

export const callerDefault: Callers = {
  callerName: null,
  phone: phoneDefault,
  countryCode: { label: 'United States +1', value: 'us' },
  number: null,
  optIn: false,
  selectedCaller: null,
  callerRelationship: relationshipDefault,
  drivers: [],
  confirmedDOB: false,
  companyConfirmation: false,
}

export type Escalate = {
  accommodationNotes: string
  disabledVehicle: string
  exchangeBranch: {
    address: string
    brand: string
    formattedCityStateZip: string
    gpbr: string
    peoplesoftId: string
    phone: string
  }
  vehicle: TransformedVehicle & { label: string }
  vehicleAccommodationSelect: { label: string; value: string }
  vehicleCondition: string
  towLocation: { name: string; address: string; cityState: string }
  ticketNumber: string
  customer: string
  customerContact: string
  number: string
  confirmedContact?: boolean
  customVin?: Maybe<string>
}

type ExchangeType = 'driveExchange' | 'towExchange'
type FirstDestination = 'branch' | 'disabledVehicle'

export type Exchange = {
  exchangeNotes: Maybe<string>
  notes: Maybe<string>
  bypassReason: string | undefined
  carClass: CarClassCategories[]
  rentalBrand: string
  rentalGPBR: string
  exchangeGPBR: string
  rentalStationId: string
  ticketNumber: string
  branch?: Branch
  type?: ExchangeType
  towDirection?: FirstDestination
  agentEID?: string
  pickupBranch?: any
  network?: SelectOption<AssignmentNetworks>
  dispatchRentalNotes?: Maybe<string>
  disabledVehiclePickup?: boolean
  isFirstRecommendedOption: boolean
  acceptReject: 'accept' | 'reject'
  passengerCount?: { label: string; value: number }
  luggageCount?: { label: string; value: number }
  vehicleAccommodationSelect: { label: string; value: string }
  accommodationNotes: string
  disabledVehicle: string
  towLocation?: { name: string; address: string; cityState: string }
  customVin?: Maybe<string>
}

export type Jumpstart = {
  vehicleStatus: string
  noise?: string
  outOfFuel?: YesNoTriggers
} & SoftService &
  Pick<WorkflowActions, 'fuel'>

export type Claim = {
  vehicle: SelectOption
  date: string
  facts: string
  state: SelectOption
  policeReport: YesNoTriggers
  policeReportNumber?: Maybe<string>
  precinctName?: Maybe<string>
  policeDepartmentName?: Maybe<string>
  roadsideTow: YesNoTriggers
  towWho?: Maybe<string>
  towWhere?: Maybe<string>
  renterDriver: string
  driverFirstName?: Maybe<string>
  driverLastName?: Maybe<string>
  driverCallbackNumber?: Maybe<string>
  driver?: Maybe<Driver>
}

export type Party = {
  firstParty?: Maybe<string>
  thirdParty?: Maybe<string>
  firstName?: Maybe<string>
  middleName?: Maybe<string>
  lastName?: Maybe<string>
  suffix?: Maybe<string>
  legalId?: Maybe<LegalId>
  address?: Maybe<DriverAddress>
  phone1?: Maybe<DriverPhone>
  phone2?: Maybe<DriverPhone>
  phone3?: Maybe<DriverPhone>
  email?: Maybe<string>
}

export type Insurance = {
  firstPartyInsurance?: YesNo
  thirdPartyInsurance?: YesNo
  companyName?: Maybe<string>
  claimNumber?: Maybe<string>
  policyNumber?: Maybe<string>
  effectiveDate?: Maybe<string>
  expirationDate?: Maybe<string>
  coverage?: Maybe<string>
  contactFirstName?: Maybe<string>
  contactLastName?: Maybe<string>
  phone?: Maybe<DriverPhone & TypeCountryOptions>
  email?: Maybe<string>
}

export type LdrClaim = {
  claim: Claim
  claimNumber: string
  callHistoryView?: boolean
}

export type Lockout = {
  lostKeys: YesNoTriggers
  personalBelongings?: YesNoTriggers
  keysLocation?: 'trunk' | 'cabin'
  trunkAccess?: YesNoTriggers
} & SoftService &
  Pick<WorkflowActions, 'exchange'>

export type Officer = {
  title: Maybe<string>
  badge: Maybe<string>
  deskPhone: Maybe<string>
  deskPhoneExt: Maybe<string>
  email: Maybe<string>
  department: Maybe<string>
  cityAndCounty: Maybe<string>
  stateOrProvince: SelectOption<string>
  /** only used for analytics */
  isVerified: boolean
}

export type Law = {
  vehicle: Maybe<Vehicle>
  isActionable: Maybe<boolean>
  notes: Maybe<string>
}

export const officerDefault: Officer = {
  title: null,
  badge: null,
  deskPhone: null,
  deskPhoneExt: null,
  email: null,
  department: null,
  cityAndCounty: null,
  isVerified: false,
  stateOrProvince: {
    label: '',
    value: '',
  },
}

export const lawDefault: Law = {
  vehicle: null,
  isActionable: false,
  notes: null,
}

export type Recovery = {
  vehicleLocation: LocationPayload
  destinationLocation: Maybe<LocationPayload>
  locationBusiness: Maybe<string>
  reason: string
  reasonOther: Maybe<string>
  keysWhere: string
  locationOther: Maybe<string>
  description: Maybe<string>
  destinationName: Maybe<string>
  recoveryGroup: Maybe<string>
  rentalGroup: string
  vehicle: Vehicle & SelectOption
  rentalGPBR: string
  policeReportNumber: string
  isDestinationDifferent?: boolean
  customVin?: Maybe<string>
}

export type Modified = {
  modified?: string
}

export type SoftService = {
  vehicle: PartialVehicle | PartialVehicleWithStringYear
  serviceLocations: LocationState
  notes: string
  dispatch?: PartialDispatch[]
  serviceCase?: PartialULYDispatch
  customVin?: Maybe<string>
} & Breakdown

export type Tires = {
  condition: TireCondition
  wereTiresStolen?: YesNoTriggers
  network?: SelectOption<AssignmentNetworks>
  participant?: Participant
  customDropoffDestination?: LocationPayload
  additionalAction?: string
  changeNeeded?: YesNoTriggers
} & Pick<SoftService, 'vehicle' | 'serviceLocations'> &
  Pick<WorkflowActions, 'exchange' | 'recovery' | 'ldr' | 'tiresChangeSpare'> &
  Breakdown

export enum TowType {
  EXCHANGE = 'exchange',
  NO_EXCHANGE = 'noExchange',
}

export type TowForm = {
  towType?: TowType | null
}

export type Tow = {
  network?: SelectOption<AssignmentNetworks>
  rideAlong?: boolean
  rideAlongSameAsDropoff?: boolean
  rideAlongLocation?: string
  customerSupplied?: boolean
  thirdPartyLocation?: Maybe<LocationPayload>
  unattended?: boolean
  keysLocation?: SelectOption<KeyLocation>
  keysLocationOther?: string
  vehicleConditionSelect: SelectOption<VehicleConditions>
  vehicleCondition?: string
  exchange?: boolean
  callerLocation?: Maybe<LocationPayload>
  customerWithVehicle?: Maybe<YesNoTriggers>
  customDropoffDestination?: LocationPayload
  passengerCount?: { label: string; value: number }
  luggageCount?: { label: string; value: number }
  customVin?: Maybe<string>
} & SoftService &
  Breakdown

export type TowExchange = Exchange & Tow

export type Winch = {
  okayToDrive: YesNoTriggers
  moreThan50ft: YesNoTriggers
} & SoftService &
  Pick<WorkflowActions, 'exchange' | 'recovery' | 'ldr'>

//ORDER IS IMPORTANT AND NON-ARBITRARY
export enum CallTypes {
  TIRES = 'Tires',
  SPARE_TIRE_CHANGE = 'Spare Tire Change',
  JUMPSTART = 'Start',
  KEYS = 'Keys',
  FUEL = 'Fuel',
  WINCH = 'Winch',
  EXCHANGE = 'Exchange',
  TOW = 'Tow',
  RECOVERY = 'Recovery',
  ACCIDENT = 'Accident',
  LDR = 'LDR',
  LAW = 'Law',
  ESCALATE = 'Escalate',
  FEATURE = 'Feature',
  DROP = 'Drop',
  TRANSFER = 'Transfer',
  GUIDED_ASSIST = 'Guided Assist',
  ADDITIONAL = 'Additional',
  CALLBACK = 'Callback',
}

export default CallTypes
